
































































































































































































































import { IUser, User } from '@/shared/model/user.model'
import userService from '@/shared/services/userService'
import Vue from 'vue'

export default Vue.extend({
  name: 'PagesRegister',

  components: {
    PagesHeading: () => import('./components/Heading.vue'),
  },

  data: () => ({
    user: new User() as IUser,
    customRules: 'required|checkusername',
    customEmailRules: 'required|email|checkemail',
    valid: false,
    consent1: false,
    consent2: false,
    registerComplete: false,
    birthdayDatePickerVisible: false,
    birthdayVal: undefined,
    activePicker: null as String | null,
  }),
  computed: {
    maxDate () {
      const now = new Date()
      const date10YearsAgo = new Date()
      date10YearsAgo.setFullYear(now.getFullYear() - 10)
      return date10YearsAgo.toISOString()
    },
  },
  watch: {
    birthdayDatePickerVisible (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  methods: {
    register (valid: any) {
      if (valid) {
        userService.signupUser(this.user).then((result: any) => {
          if (result.status === 200) {
            this.registerComplete = true
          }
        })
      }
    },

    selectDate () {
      this.birthdayDatePickerVisible = false
      if (this.user.metadata) this.user.metadata.birthday = this.birthdayVal
    },
  },
})
