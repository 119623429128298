var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height justify-center",attrs:{"id":"register","tag":"section"}},[_c('div',{staticStyle:{"width":"80%","max-width":"500px","margin-bottom":"40px"}},[_c('v-img',{attrs:{"src":require("@/assets/logo.png")}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[(!_vm.registerComplete)?_c('v-slide-y-transition',{attrs:{"appear":""}},[_c('v-card',{staticClass:"pa-3 pa-md-5 mx-auto",attrs:{"light":""}},[_c('pages-heading',{staticClass:"text-center display-3"},[_vm._v(" "+_vm._s(_vm.$t('register-headline'))+" ")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-row',{staticStyle:{"padding":"40px"}},[_c('div',{staticClass:"text-center",staticStyle:{"width":"100%"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('firstname')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":_vm.$t('firstname') + '*',"prepend-icon":"mdi-account"},model:{value:(_vm.user.metadata.firstName),callback:function ($$v) {_vm.$set(_vm.user.metadata, "firstName", $$v)},expression:"user.metadata.firstName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('lastname')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":_vm.$t('lastname') + '*',"prepend-icon":"mdi-account-circle"},model:{value:(_vm.user.metadata.lastName),callback:function ($$v) {_vm.$set(_vm.user.metadata, "lastName", $$v)},expression:"user.metadata.lastName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":_vm.customRules,"name":_vm.$t('username')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$t(errors[0]),"color":"secondary","label":_vm.$t('username') + '*',"prepend-icon":"mdi-head"},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":_vm.customEmailRules,"name":_vm.$t('email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$t(errors[0]),"color":"secondary","label":_vm.$t('email') + '*',"prepend-icon":"mdi-email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('validation-provider',{attrs:{"rules":"required|birthday","name":_vm.$t('birthday')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('birthday'),"readonly":"","prepend-icon":"mdi-calendar","error-messages":_vm.$t(errors[0]),"value":_vm._f("formatDate")(_vm.user.metadata.birthday || _vm.birthdayVal)}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.birthdayDatePickerVisible),callback:function ($$v) {_vm.birthdayDatePickerVisible=$$v},expression:"birthdayDatePickerVisible"}},[_c('v-date-picker',{ref:"pickerInDialog",attrs:{"active-picker":_vm.activePicker,"locale":"de","no-title":"","max":_vm.maxDate},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"input":_vm.selectDate},model:{value:(_vm.birthdayVal),callback:function ($$v) {_vm.birthdayVal=$$v},expression:"birthdayVal"}})],1),_c('validation-provider',{attrs:{"rules":"checkconsent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"color":"secondary","error-messages":_vm.$t(errors[0])},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticStyle:{"padding-right":"5px"}},[_vm._v(_vm._s(_vm.$t('dialog.hereby-i-agree')))])]},proxy:true}],null,true),model:{value:(_vm.consent1),callback:function ($$v) {_vm.consent1=$$v},expression:"consent1"}})]}}],null,true)}),_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"color":"secondary","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticStyle:{"padding-right":"5px"}},[_c('span',[_vm._v(_vm._s(_vm.$t('dialog.further-i-agree-to')))])])]},proxy:true}],null,true),model:{value:(_vm.consent2),callback:function ($$v) {_vm.consent2=$$v},expression:"consent2"}})]}}],null,true)}),_c('div',{staticClass:"text-left",staticStyle:{"margin-top":"20px"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('dialog.refusal-is-allways'))+" "),_c('a',{attrs:{"href":"mailto:office@evalus.at"}},[_vm._v("office@evalus.at")]),_vm._v(" "+_vm._s(_vm.$t('dialog.possible'))+" ")]),_c('br'),_c('br'),_c('div',[_vm._v(" "+_vm._s(_vm.$t('dialog.the-e-val-card-is-the'))+" ")])]),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('v-btn',{attrs:{"color":"success","disabled":!valid},on:{"click":function($event){return _vm.register(valid)}}},[_vm._v(" "+_vm._s(_vm.$t('register'))+" ")])],1)],1)])]}}],null,false,4281156874)})],1)],1):_c('v-slide-y-transition',{attrs:{"appear":""}},[_c('v-card',{staticClass:"pa-3 pa-md-5 mx-auto",attrs:{"light":""}},[_c('v-row',{staticStyle:{"padding":"25px","text-align":"center"},attrs:{"justify":"center"}},[_c('div',{staticStyle:{"width":"100%","margin-bottom":"15px"}},[_c('v-icon',{attrs:{"size":"54","color":"success"},domProps:{"textContent":_vm._s('mdi-check')}})],1),_c('div',{staticStyle:{"font-size":"1.3rem","margin-bottom":"15px","width":"100%"}},[_vm._v(" "+_vm._s(this.$t('dialog.register-successfull'))+" ")]),_c('div',{staticStyle:{"font-size":"1.2rem","margin-bottom":"15px","width":"100%"}},[_vm._v(" "+_vm._s(this.$t('dialog.this-should-not-take-longer-than'))+" ")]),_c('v-btn',{attrs:{"color":"secondary","to":"/login"}},[_vm._v(" "+_vm._s(_vm.$t('go-to-login'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }